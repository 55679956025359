import React from 'react';
import propTypes from 'prop-types';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { ROUTES, history } from '@Utils/routing';
import PageContainer from '@Components/containers/PageContainer';
import ResponsiveLayout from '@Components/controls/ResponsiveLayout';
import HomePage from '@Components/Pages/Home';
import SearchResultsPage from '@Components/Pages/SearchResults';
import ProductInfoPage from '@Components/Pages/ProductInfo';
import ScrollToTop from '@Utils/scrollToTop';
import SiteInfoPage from '@Components/Pages/SiteInfoPage';
import TrialRegistrationPage from '@Components/Pages/TrialRegistration';
import SubscribePage from '@Components/Pages/Subscribe';
import LoginPage from "@Components/Pages/Login";

/*
 * it should be always last import in this file,
 * otherwise components with data-future property may be visible
 */
import DevModeStyleSwitch from './Components/controls/DevModeStyleSwitch';
import TrialRegistration from "@Components/Pages/TrialRegistration";
import PrintRegistrationPage from "@Components/Pages/RegisterPrintSubscriber";
import AdminHomePage from "@Components/Pages/AdminHome";
import SearchResultsLazyPage from "@Components/Pages/SearchResultsLazy";
import UserProfilePage from "@Components/Pages/UserProfile";
import ShareReader from "@Components/Pages/ShareReader";
import ShareReaderPage from "@Components/Pages/ShareReader";
import CheckLibraryCardPage from "@Components/Pages/CheckLibraryCard";
import ReaderCoverPage from "@Components/Pages/ReaderCover";
import ReaderLogin from "@Components/Pages/ReaderLogin";
import NotFound from "@Components/Pages/NotFound";

const AppRoutes = ({ devMode }) => (
  <BrowserRouter>
    <ScrollToTop>
      <DevModeStyleSwitch devMode={devMode} />
      <PageContainer fullScreen>
        <Routes>
          <Route exact path={ROUTES.root} element={<HomePage />} />
          <Route exact path={ROUTES.home} element={<HomePage />} />
          <Route exact path="/default.aspx" element={<HomePage />} />
          <Route exact path={ROUTES.admin} element={<AdminHomePage editor='Announcement' />} />
          <Route exact path={ROUTES.adm_announcement} element={<AdminHomePage editor='Announcement' />} />
          <Route exact path={ROUTES.adm_cur_issues} element={<AdminHomePage editor='CurrentIssue' />} />
          <Route exact path={ROUTES.adm_high_demand} element={<AdminHomePage editor='HighDemand' />} />
          <Route exact path={ROUTES.adm_top_categories} element={<AdminHomePage editor='TopCategories' />} />
          <Route exact path={ROUTES.login} element={<LoginPage />} />
            <Route exact path="/ReaderCover" element={<ReaderCoverPage />} />
          <Route exact path="/GeneralInfo.aspx" element={<SiteInfoPage />} />
          <Route exact path="/PrintIssue.aspx" element={<SiteInfoPage siteInfoId={86} />} />
          <Route exact path="/PrintIssueU.aspx" element={<SiteInfoPage siteInfoId={87} />} />
          <Route exact path="/PrintIssueU.aspx" element={<SiteInfoPage siteInfoId={87} />} />
          <Route path={ROUTES.searchResuts} element={<SearchResultsPage />} />
          <Route path={ROUTES.searchResutsLazy} element={<SearchResultsLazyPage />} >
            <Route path={ROUTES.bySearchKey} element={<SearchResultsLazyPage />} />
          </Route>
          <Route path={ROUTES.products} element={<ProductInfoPage />}>
            <Route path={ROUTES.byId} element={<ProductInfoPage />} />
          </Route>
          <Route path={"/:producttitle/:productId"} element={<ProductInfoPage />}>
          </Route>
          <Route exact path={ROUTES.subscriptions} element={<SiteInfoPage siteInfoId={67} />} />
          <Route exact path={ROUTES.issues} element={<SiteInfoPage siteInfoId={86} />} />
          <Route exact path={ROUTES.liveEvents} element={<SiteInfoPage siteInfoId={88} />} />
          <Route exact path={ROUTES.issuesLogin} element={<ReaderLogin />} />

          <Route exact path={ROUTES.readerIssues} element={<SiteInfoPage siteInfoId={108} />} />
          <Route exact path={ROUTES.booklistBlogDash} element={<SiteInfoPage siteInfoId={108} />} />
          <Route exact path={ROUTES.readerIssuesLogin} element={<ReaderLogin />} />
          <Route exact path={ROUTES.readerIssuesLoginDash} element={<ReaderLogin />} />

          <Route exact path={ROUTES.booklinksIssues} element={<SiteInfoPage siteInfoId={86} />} />
          <Route exact path={ROUTES.booklinks} element={<SiteInfoPage siteInfoId={47} />} />
          <Route exact path={ROUTES.booklinksdash} element={<SiteInfoPage siteInfoId={47} />} />
          <Route exact path={ROUTES.webinars} element={<SiteInfoPage siteInfoId={63} />} />
          <Route exact path={ROUTES.commoncore} element={<SiteInfoPage siteInfoId={68} />} />
          <Route exact path={ROUTES.whoWeAre} element={<SiteInfoPage siteInfoId={112} />} />
          <Route exact path={ROUTES.bookAwards} element={<SiteInfoPage siteInfoId={46} />} />
          <Route exact path={ROUTES.onlineExclusives} element={<SiteInfoPage siteInfoId={102} />} />
          <Route exact path={ROUTES.bookReviews} element={<SiteInfoPage siteInfoId={102} />} />
          <Route exact path={ROUTES.booklisters} element={<SiteInfoPage siteInfoId={57} />} />
          <Route exact path={ROUTES.links} element={<SiteInfoPage siteInfoId={62} />} />
          <Route exact path={ROUTES.awards} element={<SiteInfoPage siteInfoId={46} />} />
          <Route exact path={ROUTES.bookAwardsDash} element={<SiteInfoPage siteInfoId={46} />} />
          <Route exact path={ROUTES.greatReads} element={<SiteInfoPage siteInfoId={45} />} />
          <Route exact path={ROUTES.greatReadsDash} element={<SiteInfoPage siteInfoId={45} />} />
          <Route exact path={ROUTES.webinarsArchive} element={<SiteInfoPage siteInfoId={73} />} />
          <Route exact path={ROUTES.videoReview} element={<SiteInfoPage siteInfoId={79} />} />
          <Route exact path={ROUTES.allThingsAudio} element={<SiteInfoPage siteInfoId={94} />} />
          <Route exact path={ROUTES.quickTipsForSchools} element={<SiteInfoPage siteInfoId={77} />} />
          <Route exact path={ROUTES.GNSweepstakes22} element={<SiteInfoPage siteInfoId={112} />} />
          <Route exact path={ROUTES.GNSweepstakes23} element={<SiteInfoPage siteInfoId={120} />} />
          <Route exact path={ROUTES.GNSweepstakes24} element={<SiteInfoPage siteInfoId={121} />} />
          <Route exact path={ROUTES.topShelfReference} element={<SiteInfoPage siteInfoId={85} />} />
          <Route exact path={ROUTES.booklistCelebrates50YearsofYA} element={<SiteInfoPage siteInfoId={91} />} />
          <Route exact path={ROUTES.booklandia} element={<SiteInfoPage siteInfoId={84} />} />
          <Route exact path={ROUTES.bookmakers} element={<SiteInfoPage siteInfoId={81} />} />
          <Route exact path={ROUTES.commonCoreStateStandards} element={<SiteInfoPage siteInfoId={68} />} />
          <Route exact path={ROUTES.manageNewsletters} element={<SiteInfoPage siteInfoId={75} />} />
          <Route exact path={ROUTES.videoTutorials} element={<SiteInfoPage siteInfoId={82} />} />
          <Route exact path={ROUTES.unlimitedUseSubscriptions} element={<SiteInfoPage siteInfoId={83} />} />
          <Route exact path={ROUTES.followBooklistOnSocialMedia} element={<SiteInfoPage siteInfoId={89} />} />
          <Route exact path={ROUTES.writingForBooklist} element={<SiteInfoPage siteInfoId={90} />} />
          <Route exact path={ROUTES.DKLifeStoriesSweepstakes} element={<SiteInfoPage siteInfoId={92} />} />
          <Route exact path={ROUTES.socialMedia} element={<SiteInfoPage siteInfoId={89} />} />
          <Route exact path={ROUTES.freeSchoolSubscriptions} element={<SiteInfoPage siteInfoId={93} />} />
          <Route exact path={ROUTES.shelfCare} element={<SiteInfoPage siteInfoId={96} />} />
          <Route exact path={ROUTES.literacyandequity} element={<SiteInfoPage siteInfoId={101} />} />
          <Route exact path={ROUTES.gnsweeptakes} element={<SiteInfoPage siteInfoId={105} />} />

          <Route exact path={ROUTES.newsletters} element={<SiteInfoPage siteInfoId={64} />} />
          <Route exact path={ROUTES.newslettersSignUp} element={<SiteInfoPage siteInfoId={64} />} />
          <Route exact path={ROUTES.readAlert} element={<SiteInfoPage siteInfoId={76} />} />
          <Route exact path={ROUTES.quickTips} element={<SiteInfoPage siteInfoId={78} />} />
          <Route exact path={ROUTES.cornerShelf} element={<SiteInfoPage siteInfoId={80} />} />
          <Route exact path={ROUTES.cornerShelfDash} element={<SiteInfoPage siteInfoId={80} />} />

          <Route exact path={ROUTES.upcomingWebinars} element={<SiteInfoPage siteInfoId={63} />} />
          <Route exact path={ROUTES.archivedWebinars} element={<SiteInfoPage siteInfoId={73} />} />

          <Route exact path={ROUTES.shelfCareFull} element={<SiteInfoPage siteInfoId={96} />} />
          <Route exact path={ROUTES.interviews} element={<SiteInfoPage siteInfoId={114} />} />

          <Route exact path={ROUTES.booklistBlog} element={<SiteInfoPage siteInfoId={106} />} />

          <Route exact path={ROUTES.staff} element={<SiteInfoPage siteInfoId={57} />} />
          <Route exact path={ROUTES.essentialLinks} element={<SiteInfoPage siteInfoId={115} />} />
          <Route exact path={ROUTES.reviewPolicies} element={<SiteInfoPage siteInfoId={116} />} />

          <Route exact path={ROUTES.advertise} element={<SiteInfoPage siteInfoId={56} />} />
          <Route exact path={ROUTES.contactUs} element={<SiteInfoPage siteInfoId={59} />} />
          <Route exact path={ROUTES.writeForUs} element={<SiteInfoPage siteInfoId={90} />} />
          <Route exact path={ROUTES.reviewers} element={<SiteInfoPage siteInfoId={66} />} />
          <Route exact path={ROUTES.getReviewed} element={<SiteInfoPage siteInfoId={65} />} />
          <Route exact path={ROUTES.faq} element={<SiteInfoPage siteInfoId={60} />} />
          <Route exact path={ROUTES.faq} element={<SiteInfoPage siteInfoId={108} />} />

          <Route exact path='/UserSubscription.aspx' element={<SubscribePage />} />
          <Route exact path='/registerprintsubscriber.aspx' element={<SubscribePage />} />
          <Route exact path={ROUTES.subscribe} element={<SubscribePage />} />
          <Route exact path={ROUTES.trialreg} element={<TrialRegistration />} />
          <Route exact path='/TrialRegistration.aspx' element={<TrialRegistration />} />
          <Route exact path={ROUTES.printreg} element={<PrintRegistrationPage />} />

          <Route exact path={ROUTES.userProfile} element={<UserProfilePage />} />
          <Route exact path={ROUTES.profile} element={<UserProfilePage />} />
          <Route exact path={ROUTES.myprofile} element={<UserProfilePage />} />
          <Route exact path={ROUTES.shareReader} element={<ShareReaderPage />} />
          <Route exact path={ROUTES.checkCardAccess} element={<CheckLibraryCardPage />} />

          <Route exact path={ROUTES.dev} element={<ResponsiveLayout />} />
          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </PageContainer>
    </ScrollToTop>
  </BrowserRouter>
);

AppRoutes.propTypes = {
  devMode: propTypes.bool.isRequired,
};

export default AppRoutes;
