import { createBrowserHistory } from 'history';
import memoize from 'fast-memoize';

const history = createBrowserHistory();

const ROUTES = {
  root: '/',
  home: '/home',
  dev: '/dev',
  login: '/login',
  admin: '/admin',
  adm_announcement:"/adm-announcement",
  adm_cur_issues:"/adm-cur-issues",
  adm_high_demand:"/adm-high-demand",
  adm_top_categories:"/adm-top-categories",
  signIn: '/signIn',
  signOut: '/signOut',
  searchResuts: '/searchResults',
  searchResutsLazy: '/search-results',
  products: '/products',
  byId: ':productId',
  bySearchKey: ':searchKey',
  userProfile: '/user-profile',
  shareReader: '/manage-reader-access',
  checkCardAccess: '/check-card-access',
  readAlert: '/read-alert',
  newsletters: '/newsletters',
  newslettersSignUp: '/newslettersSignUp',
  subscribe: '/subscribe',
  subscriptions: '/subscriptions',
  trialreg: '/trialreg',
  printreg: '/printreg',
  issues: '/issues',
  issuesLogin: '/issues-login',
  readerIssues: '/reader-issues',
  readerIssuesLogin: '/read-iss-login',
  readerIssuesLoginDash: '/reader-issues-login',
  booklinks: '/booklinks',
  booklinksIssues: '/booklinksIssues',
  whoWeAre: '/whoWeAre',
  bookAwards: '/bookAwards',
  bookAwardsDash: '/book-awards',
  onlineExclusives: '/onlineExclusives',
  quickTips: '/quickTips',
  cornerShelf: '/cornerShelf',
  cornerShelfDash: '/corner-shelf',
  upcomingWebinars: '/upcomingWebinars',
  archivedWebinars: '/archivedWebinars',
  webinarsArchive: '/webinars-archive',
  webinars: '/webinars',
  shelfCareFull: '/shelfCareFull',
  interviews: '/interviews',
  booklistBlog: '/booklistBlog',
  booklistBlogDash: '/booklist-blog',
  staff:'/staff',
  essentialLinks:'/essential-links',
  reviewPolicies:'/review-policies',
  advertise:'/advertise',
  contactUs:'/contact-us',
  writeForUs:'/write-for-us',
  reviewers:'/reviewers',
  getReviewed:'/get-reviewed',
  faq:'/faq',
  liveEvents:'/live-events',
  booklinksdash:'/book-links',
  commoncore:'/commoncore',
  myprofile:'/myprofile',
  profile:'/profile',
  booklisters:'/booklisters',
  links:'/links',
  awards:'/awards',
  greatReads:'/greatreads',
  greatReadsDash:'/great-reads',
  videoReview:'/video-review',
  allThingsAudio:'/all-things-audio',
  quickTipsForSchools:'/quick-tips-for-schools-and-libraries',
  GNSweepstakes22:'/GNSweepstakes22',
  GNSweepstakes23:'/GNSweepstakes23',
  GNSweepstakes24:'/GNSweepstakes24',
  topShelfReference:'/top-shelf-reference',
  booklistCelebrates50YearsofYA:'/Booklist-Celebrates-50YearsofYA',
  booklandia:'/booklandia',
  bookmakers:'/bookmakers',
  commonCoreStateStandards:'/common-core-standards-printable-resources',
  manageNewsletters:'/manage-newsletters',
  videoTutorials:'/video-tutorials',
  unlimitedUseSubscriptions:'/unlimited-use-subscriptions',
  followBooklistOnSocialMedia:'/follow-booklist-on-social-media',
  writingForBooklist:'/writing-for-booklist',
  DKLifeStoriesSweepstakes:'/DKLifeStoriesSweepstakes',
  socialMedia:'/social-media',
  bookReviews:'/book-reviews',
  freeSchoolSubscriptions:'/free-school-subscriptions',
  shelfCare:'/shelf-care',
  literacyandequity:'/literacyandequity',
  gnsweeptakes:'/gnsweeptakes',
};

const navigateTo = (route, replace, routeParams = {}) => {
  const effectiveRoute = Object.keys(routeParams).reduce(
    (acc, param) => acc.replace(`:${param}`, routeParams[param]),
    route
  );
  if (replace) {
    history.replace(effectiveRoute);
    return;
  }
  history.push(effectiveRoute);
};
const navigatorTo = memoize((route) => () => navigateTo(route));

top.goDev = (route = 'dev') => navigateTo(ROUTES[route] || route);

export { ROUTES, history, navigateTo, navigatorTo };
