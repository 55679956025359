import React, {useCallback, useEffect, useState} from 'react';
import { NavigationAreaRenderer } from '@Components/sections/SideMenu/NavigationArea/Renderers';
import NavigationItem from '@Components/sections/SideMenu/NavigationArea/NavigationItem';
import { NavigationSubitemRenderer } from '@Components/sections/SideMenu/NavigationArea/NavigationItem/Renderers';
import {Link} from "react-router-dom";
import {announcementInterface, appInterface} from "@Store";
import {useSelector} from "react-redux";

const NavigationArea = () => {
    const handleMenuNavClick = useCallback(() => {
        appInterface.dispatch(appInterface.setSideMenuOpen({ sideMenuOpen: false }));
    }, []);

    const dataSource2 = useSelector(announcementInterface.getCurrentAnnouncement);

    const [currIssueLink, setCurrIssueLink] = useState('');
    useEffect(() => {
        if(dataSource2 && dataSource2.CurrIssue) {
            setCurrIssueLink(dataSource2.CurrIssue.ItemBody);
        }

    }, [dataSource2]);

  return (
    <NavigationAreaRenderer>
      <NavigationItem itemId="1" title="Reviews">
        <NavigationSubitemRenderer>
            <Link onClick={handleMenuNavClick} to={currIssueLink}>Current Issue</Link>
        </NavigationSubitemRenderer>
        <NavigationSubitemRenderer>
            <Link onClick={handleMenuNavClick} to={`/onlineExclusives`}>Booklist Online Exclusive Reviews</Link>
        </NavigationSubitemRenderer>
      </NavigationItem>
      <NavigationItem itemId="2" title="Magazines">
        <NavigationSubitemRenderer>
            <Link onClick={handleMenuNavClick} to={`/issues`}>Booklist</Link>
        </NavigationSubitemRenderer>
        <NavigationSubitemRenderer>
            <Link onClick={handleMenuNavClick} to={`/reader-issues`}>Booklist Reader</Link>
        </NavigationSubitemRenderer>
      </NavigationItem>
      <NavigationItem itemId="3" title="Webinars&nbsp;&&nbsp;Media">
        <NavigationSubitemRenderer>
            <Link onClick={handleMenuNavClick} to={`/upcomingWebinars`}>Upcoming Webinars</Link>
        </NavigationSubitemRenderer>
        <NavigationSubitemRenderer>
            <Link onClick={handleMenuNavClick} to={`/archivedWebinars`}>Webinar Archive</Link>
        </NavigationSubitemRenderer>
        <NavigationSubitemRenderer>
            <Link onClick={handleMenuNavClick} to={`/shelfCareFull`}>Shelf Care Podcast</Link>
        </NavigationSubitemRenderer>
        <NavigationSubitemRenderer>
            <Link onClick={handleMenuNavClick} to={`/interviews`}>Interviews</Link>
        </NavigationSubitemRenderer>
      </NavigationItem>
      <NavigationItem itemId="7" link='/booklistBlog' title="Booklist&nbsp;Blog">
      </NavigationItem>
      <NavigationItem itemId="4" title="Newsletters">
          <NavigationSubitemRenderer>
              <Link onClick={handleMenuNavClick} to={`/newsletters`}>Sign up for Newsletters</Link>
          </NavigationSubitemRenderer>
        <NavigationSubitemRenderer>
            <Link onClick={handleMenuNavClick} to={`/cornerShelf`}>Corner Shelf</Link>
        </NavigationSubitemRenderer>
        <NavigationSubitemRenderer>
            <Link onClick={handleMenuNavClick} to={`/quickTips`}>Quick Tips for Schools & Libraries</Link>
        </NavigationSubitemRenderer>
        <NavigationSubitemRenderer>
            <Link onClick={handleMenuNavClick} to={`/read-alert`}>Read Alert</Link>
        </NavigationSubitemRenderer>
      </NavigationItem>
      <NavigationItem itemId="5" title="Resources">
        <NavigationSubitemRenderer>
            <Link onClick={handleMenuNavClick} to={`/bookAwards`}>Book Awards</Link>
        </NavigationSubitemRenderer>
          <NavigationSubitemRenderer>
              <Link onClick={handleMenuNavClick} to={`/review-policies`}>Review Policies</Link>
          </NavigationSubitemRenderer>
      </NavigationItem>
      <NavigationItem itemId="6" title="More">
        <NavigationSubitemRenderer>
            <Link onClick={handleMenuNavClick} to={`/staff`}>Who We Are</Link>
        </NavigationSubitemRenderer>
        <NavigationSubitemRenderer>
            <Link onClick={handleMenuNavClick} to={`/faq`}>FAQ</Link>
        </NavigationSubitemRenderer>
        <NavigationSubitemRenderer>
            <a href={`https://booklist.secure.darwin.cx/I1YANEWB`}>Subscriptions</a>
        </NavigationSubitemRenderer>
        <NavigationSubitemRenderer>
            <Link onClick={handleMenuNavClick} to={`/advertise`}>Advertise</Link>
        </NavigationSubitemRenderer>
        <NavigationSubitemRenderer>
            <Link onClick={handleMenuNavClick} to={`/get-reviewed`}>Get Reviewed</Link>
        </NavigationSubitemRenderer>
        <NavigationSubitemRenderer>
            <Link onClick={handleMenuNavClick} to={`/write-for-us`}>Write for Us</Link>
        </NavigationSubitemRenderer>
      </NavigationItem>
    </NavigationAreaRenderer>
  );
};

export default NavigationArea;
