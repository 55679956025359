import React, {useEffect, useState} from 'react';
import { NavigationAreaRenderer } from '@Components/sections/TopMenu/NavigationArea/Renderers';
import { Visible } from '@Components/containers/Grid';
import NavigationItem from '@Components/sections/TopMenu/NavigationArea/NavigationItem';
import { NavigationSubitemRenderer } from '@Components/sections/TopMenu/NavigationArea/NavigationItem/Renderers';
import { Link } from 'react-router-dom';
import {useHandleLoadAnnouncement} from "@Api/hooks/announcement";
import {useSelector} from "react-redux";
import {announcementInterface} from "@Store";

const NavigationArea = () => {
  const [loadData2, isLoading2] = useHandleLoadAnnouncement(false);
  const dataSource2 = useSelector(announcementInterface.getCurrentAnnouncement);

  const [currIssueLink, setCurrIssueLink] = useState('');
  useEffect(() => {
    if(dataSource2 && dataSource2.CurrIssue) {
      setCurrIssueLink(dataSource2.CurrIssue.ItemBody);
    }
  }, [dataSource2]);


  return (
    <NavigationAreaRenderer>
      <Visible xxl xl>
        <NavigationItem title="Reviews">
          <NavigationSubitemRenderer>
            <Link to={currIssueLink}>Current Issue</Link>
          </NavigationSubitemRenderer>
          <NavigationSubitemRenderer>
            <Link to={`/onlineExclusives`}>Booklist Online Exclusive Reviews</Link>
          </NavigationSubitemRenderer>
        </NavigationItem>
        <NavigationItem title="Magazines">
          <NavigationSubitemRenderer>
            <Link to={`/issues`}>Booklist</Link>
          </NavigationSubitemRenderer>
          <NavigationSubitemRenderer>
            <Link to={`/reader-issues`}>Booklist Reader</Link>
          </NavigationSubitemRenderer>
        </NavigationItem>
        <NavigationItem title="Webinars&nbsp;&&nbsp;Media">
          <NavigationSubitemRenderer>
            <Link to={`/upcomingWebinars`}>Upcoming Webinars</Link>
          </NavigationSubitemRenderer>
          <NavigationSubitemRenderer>
            <Link to={`/archivedWebinars`}>Webinar Archive</Link>
          </NavigationSubitemRenderer>
          <NavigationSubitemRenderer>
            <Link to={`/shelfCareFull`}>Shelf Care Podcast</Link>
          </NavigationSubitemRenderer>
          <NavigationSubitemRenderer>
            <Link to={`/interviews`}>Interviews</Link>
          </NavigationSubitemRenderer>
        </NavigationItem>
        <NavigationItem title="Booklist&nbsp;Blog" link={'/booklistBlog'}>
        </NavigationItem>
        <NavigationItem title="Newsletters" link={'/newsletters'}>
          <NavigationSubitemRenderer>
            <Link to={`/newsletters`}>Sign up for Newsletters</Link>
          </NavigationSubitemRenderer>
          <NavigationSubitemRenderer>
            <Link to={`/cornerShelf`}>Corner Shelf</Link>
          </NavigationSubitemRenderer>
          <NavigationSubitemRenderer>
            <Link to={`/quickTips`}>Quick Tips for Schools & Libraries</Link>
          </NavigationSubitemRenderer>
          <NavigationSubitemRenderer>
            <Link to={`/read-alert`}>Read Alert</Link>
          </NavigationSubitemRenderer>
        </NavigationItem>
        <NavigationItem title="Resources" link={'/newsletters'}>
          <NavigationSubitemRenderer>
            <Link to={`/bookAwards`}>Book Awards</Link>
          </NavigationSubitemRenderer>
          { /*<NavigationSubitemRenderer>
            <Link to={`/essential-links`}>Essential Lists</Link>
          </NavigationSubitemRenderer>*/ }
          <NavigationSubitemRenderer>
            <Link to={`/review-policies`}>Review Policies</Link>
          </NavigationSubitemRenderer>
        </NavigationItem>
        <NavigationItem title="More">
          <NavigationSubitemRenderer>
            <Link to={`/staff`}>Who We Are</Link>
          </NavigationSubitemRenderer>
          <NavigationSubitemRenderer>
            <Link to={`/faq`}>FAQ</Link>
          </NavigationSubitemRenderer>
          <NavigationSubitemRenderer>
            <Link to={`/subscriptions`}>Subscriptions</Link>
          </NavigationSubitemRenderer>
          <NavigationSubitemRenderer>
            <Link to={`/advertise`}>Advertise</Link>
          </NavigationSubitemRenderer>
          <NavigationSubitemRenderer>
            <Link to={`/get-reviewed`}>Get Reviewed</Link>
          </NavigationSubitemRenderer>
          <NavigationSubitemRenderer>
            <Link to={`/write-for-us`}>Write for Us</Link>
          </NavigationSubitemRenderer>
        </NavigationItem>
      </Visible>
    </NavigationAreaRenderer>
  );
};

export default NavigationArea;
